<template>
  <div class="page-body">
    <div class="card mt-3">
      <div class="card-body">
        <h3>Welcome to SKAARHOJ Authentication Service 🎉</h3>
        <p>
          Authentication Service lets You to sign in once and access all of the
          SKAARHOJ services.
        </p>
        <p>Here you can manage all setting of your accout.</p>
      </div>
    </div>
    <div v-if="isNotifications">
      <h3 class="mt-4">Notifications:</h3>
      <div class="card mt-1">
        <div class="card-body">
          <div class="divide-y">
            <div v-if="Notifications.TwoFaStatus === false">
              <div class="row">
                <div class="col-auto">
                  <span class="avatar"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-alert-triangle"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M12 9v2m0 4v.01"></path>
                      <path
                        d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
                      >
                    </path></svg>
                    </span>
                </div>
                <div class="col">
                  <div class="text-truncate">
                    <strong
                      >Two factor authentication is not enabled yet.</strong
                    >
                  </div>
                  <div class="text-muted">
                    Two-factor authentication adds an additional layer of
                    security to your account by requiring more than just a
                    password to sign in.
                  </div>
                </div>
                <div class="col-auto align-self-center">
                  <button
                    class="btn btn-outline-success"
                    @click="$router.push('/settings/twofa')"
                  >
                    Enable
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card mt-1"
        v-for="invite of Notifications.Invites"
        :key="invite.id"
      >
        <div class="card-body">
          <div class="divide-y">
            <div>
              <div class="row">
                <div class="col-auto">
                  <span class="avatar">
                    <img :src="invite.organization_image" />
                  </span>
                </div>
                <div class="col">
                  <div class="text-truncate">
                    <strong
                      >{{ invite.organization_name }} is inviting you to join
                      the organization</strong
                    >
                  </div>
                </div>
                <div class="col-auto align-self-center">
                  <button
                    class="btn btn-outline-success"
                    style="margin-right: 5px"
                    @click="acceptInvite(invite.id)"
                  >
                    Accept
                  </button>
                  <button
                    class="btn btn-outline-danger"
                    @click="declineInvite(invite.id)"
                  >
                    Decline
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { api } from '@/api'
export default {
	name: 'Home',
	data () {
		return {
			Notifications: {
				TwoFaStatus: true,
				Invites: []
			}
		}
	},
	computed: {
		...mapState({
			user: (state) => state.user
		}),
		isNotifications () {
			return this.Notifications.TwoFaStatus !== true || this.Notifications.Invites?.length
		},
		isSuperAdmin () {
			return this.user?.Role?.find((x) => x.role === 'SUPER_ADMIN')
		},
		isInit () {
			return (
				this.user?.Role?.find((x) => x.role === 'INIT.SKAARHOJ.COM') ||
        this.isSuperAdmin
			)
		}
	},
	methods: {
		...mapActions([
			'logout',
			'tokenCreate',
			'getTokens',
			'removeToken',
			'organizationCreate'
		]),
		...mapMutations(['loginSuccess']),
		initLogout () {
			this.logout()
		},
		acceptInvite (id) {
			api.get('/user/invite/' + id + '/accept').then(() => {
				this.fetchInvites()
			})
		},
		declineInvite (id) {
			api.get('/user/invite/' + id + '/decline').then(() => {
				this.fetchInvites()
			})
		},
		fetchTwoFaStatus () {
			api
				.get('/twofa/status')
				.then((response) => {
					if (response.data.Status === 'ok') {
						this.$set(this.Notifications, 'TwoFaStatus', response.data.Data)
					}
				})
				.catch(() => {})
		},
		fetchInvites () {
			api
				.get('/user/invites')
				.then((response) => {
					if (response.data.Status === 'ok') {
						this.$set(this.Notifications, 'Invites', response.data.Data)
					}
				})
				.catch(() => {})
		}
	},
	mounted () {
		this.fetchTwoFaStatus()
		this.fetchInvites()
	}
}
</script>
<style scoped>
.accordion-button::after {
  margin-left: 0;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgb(55, 55, 55) !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: rgb(55, 55, 55) !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: rgb(55, 55, 55) !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: rgb(55, 55, 55) !important;
}
input.form-control {
  background-color: #fff;
}
</style>
